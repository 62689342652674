
$icon-font-family: 'mugicons';
$icon-path: '../fonts/';

$icon-category: "\e900";
$icon-tags: "\e901";
$icon-comments: "\e902";
$icon-comment-o: "\e903";
$icon-comments-o: "\e905";
$icon-search: "\e906";
$icon-star-o: "\e907";
$icon-heart: "\e908";
$icon-share: "\e909";
$icon-facebook: "\e90a";
$icon-facebook-rounded: "\e90b";
$icon-twitter: "\e90c";
$icon-twitter-rounded: "\e90d";
$icon-feed: "\e90e";
$icon-github: "\e90f";

@font-face {
  font-family: $icon-font-family;
  src: url('#{$icon-path}#{$icon-font-family}.eot?pfzr1n');
  src: url('#{$icon-path}#{$icon-font-family}.eot?pfzr1n#iefix') format('embedded-opentype'),
       url('#{$icon-path}#{$icon-font-family}.ttf?pfzr1n') format('truetype'),
       url('#{$icon-path}#{$icon-font-family}.woff?pfzr1n') format('woff'),
       url('#{$icon-path}#{$icon-font-family}.svg?pfzr1n##{$icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: $icon-font-family;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-category:before { content: $icon-category; }
.icon-tags:before { content: $icon-tags; }
.icon-comments:before { content: $icon-comments; }
.icon-comments-o:before { content: $icon-comments-o; }
.icon-comment-o:before { content: $icon-comment-o; }
.icon-search:before { content: $icon-search; }
.icon-star-o:before { content: $icon-star-o; }
.icon-heart:before { content: $icon-heart; }
.icon-share:before { content: $icon-share; }
.icon-facebook:before { content: $icon-facebook; }
.icon-facebook-rounded:before { content: $icon-facebook-rounded; }
.icon-twitter:before { content: $icon-twitter; }
.icon-twitter-rounded:before { content: $icon-twitter-rounded; }
.icon-feed:before { content: $icon-feed; }
.icon-github:before { content: $icon-github; }

