//=================================
//=            Variables          =
//=================================

$primary-color: #34495e !default;
$secondary-color: #22313f !default;

$black-color: #000 !default;
$silver-color: #eee !default;
$white-color: #fff !default;

$bg-color: #f7f8f9 !default;
$txt-color: rgba($black-color, .8) !default;

$font-family-base: "Ubuntu", Helvetica, sans-serif !default;
$font-size-base: 18px !default;
$line-height-base: 1.6 !default;
$font-weight-base: 400 !default;
$font-weight-bold: 700 !default;

$link-color: $primary-color !default;
$link-hover-color: darken($link-color, 10%) !default;

$default-feature: min-width !default;
$container-width: 38em !default;
$gutter-width: 30px !default;
$gutter-padding: ($gutter-width / 2) !default;

$screen-phone   : 30em !default;
$screen-tablet  : 37em !default;
$screen-desktop : 56em !default;