//
// Placeholders
//

%clearfix {
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  *zoom: 1;
}